import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p>Syn Stunning Corp.</p>
        <p>
          Contact Number: +1 949-678-8086
        </p>
        <p>Contact E-mail: synstunning@gmail.com</p>
        <p>
          Address: Spaces The Quad, 530 Technology Dr, Suite 100, Irvine, CA
          92618, USA
        </p>
        <p>Copyright &copy; ondoc.co.kr All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
